import { Transition } from '@headlessui/react'
import { Bars3Icon, ChevronDownIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { Form, Link, useLocation, useMatches, useSearchParams } from '@remix-run/react'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { ROUTESCANNER_ADMINISTRATOR, SCHEDULE_BUILDER_ADMIN, SCHEDULE_BUILDER_EXTERNAL } from '~/constants/roles'
import NotificationsIcon from '~/icons/bell'
import DocumentationIcon from '~/icons/book'
import DataIcon from '~/icons/globe-wired'
import CompanyIcon from '~/icons/home'
import ByFeatureIcon from '~/icons/location-in-square'
import ByRoleIcon from '~/icons/profile-square'
import SvgLogo from '~/icons/svg-logo'
import type { UserProfile } from '~/types/auth'

type Props = {
  profile?: UserProfile | null
  scheduleBuilderUrl?: string
  isDarkHeader?: boolean
}
export function Header({ profile, scheduleBuilderUrl = '', isDarkHeader }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const returnTo = encodeURIComponent(location.pathname + location.search)
  const loginUrl = returnTo ? `/login?returnTo=${returnTo}` : '/login'
  const matches = useMatches()
  const { pathname } = matches[matches.length - 1]
  const showToolButton = !matches.some(match => match.pathname.startsWith('/app'))
  return (
    <>
      <nav className={classNames('z-50 md:min-h-[62px]', isDarkHeader ? 'bg-brand-tertiary' : 'bg-white')}>
        <div className={classNames('mx-auto px-4 sm:px-6', !isDarkHeader && 'max-w-7xl')}>
          <div className="relative flex items-center justify-end md:min-h-[62px]">
            <div className="absolute left-0 top-1/2 z-10 flex-shrink-0 -translate-y-1/2">
              <Link to={`/?${searchParams.toString()}`}>
                {isDarkHeader ? (
                  <SvgLogo className="h-4 w-60 fill-white hover:fill-brand-primary-200 lg:w-48" />
                ) : (
                  <SvgLogo className="h-5 w-60 cursor-pointer fill-brand-secondary leading-6 hover:fill-brand-secondary-400 sm:h-10 lg:w-48" />
                )}
              </Link>
            </div>
            <NavigationMenu.Root className=" absolute left-1/2 top-1/2 z-[1] hidden w-full -translate-x-1/2 -translate-y-1/2 lg:flex lg:flex-grow lg:justify-center">
              <NavigationMenu.List
                className={classNames(
                  'center m-0 flex list-none rounded-[6px] bg-transparent p-1',
                  isDarkHeader ? 'text-white hover:text-gray-100' : 'text-inherit'
                )}>
                <NavigationMenu.Item>
                  <Link
                    to="/"
                    className={classNames('NavigationMenuLink block select-none px-3 leading-none no-underline', isDarkHeader ? 'py-1' : 'py-2')}>
                    <span
                      className={classNames(
                        'mb-[2px] inline-flex whitespace-nowrap border-b-2 pb-0 text-[15px] font-bold leading-[1.2]',
                        isDarkHeader
                          ? 'text-white hover:border-b-white hover:text-white'
                          : 'text-brand-secondary hover:border-b-brand-primary hover:text-brand-secondary-350',
                        pathname === '/' ? 'border-b-brand-primary' : 'border-b-transparent'
                      )}>
                      Home
                    </span>
                  </Link>
                </NavigationMenu.Item>
                <NavigationMenu.Item>
                  <NavigationMenu.Trigger
                    className={classNames('group flex select-none items-center justify-between gap-[2px] px-3', isDarkHeader ? 'py-1' : 'py-2')}>
                    <span
                      className={classNames(
                        'mb-[2px] pb-0 text-[15px] font-bold leading-[1.2] hover:border-b-2',
                        isDarkHeader
                          ? 'text-white hover:border-b-white hover:text-white'
                          : 'text-brand-secondary hover:border-b-brand-primary hover:text-brand-secondary-350'
                      )}>
                      Solutions
                    </span>
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute left-0 top-0 w-full sm:w-auto">
                    <ul className="one m-0 flex list-none gap-x-[10px] p-[22px] lg:justify-center">
                      <li>
                        <h6 className="mb-1 flex items-center border-b border-gray-200 pb-2 font-bold">
                          <div className="flex h-8 w-8 items-center justify-center">
                            <ByFeatureIcon className="h-6 w-6" />
                          </div>
                          <span className="text-xs uppercase">Features</span>
                        </h6>
                        <div className="m-0 flex list-none gap-x-[10px] lg:justify-center">
                          <div>
                            <div className="m-2 my-4 text-xs font-semibold uppercase">Supply Chain Design</div>
                            <NavigationLink navLinkText="Door to Door" href="/features/door-to-door" className="min-w-[20rem] py-4">
                              <p className="max-w-[20rem] whitespace-normal pt-1 text-sm font-normal leading-normal text-gray-700">
                                Explore your optimal container route from any address to any place in the world
                              </p>
                            </NavigationLink>
                            <NavigationLink navLinkText="Route Optimizer" href="/features/route-optimizer" className="py-4">
                              <p className="max-w-[20rem] whitespace-normal pt-1 text-sm font-normal leading-normal text-gray-700">
                                Analyze when you can reduce emissions by finding better routes for your trade lanes
                              </p>
                            </NavigationLink>
                            <NavigationLink navLinkText="Connectivity Comparison" href="/app/connectivity-comparison" className="py-4">
                              <p className="max-w-[20rem] whitespace-normal pt-1 text-sm font-normal leading-normal text-gray-700">
                                Explore how ports, terminals and regions are connected
                              </p>
                            </NavigationLink>
                          </div>
                          <div>
                            <div className="m-2 my-4 text-xs font-semibold uppercase">Carbon Footprint Reporting</div>
                            <NavigationLink navLinkText="Emissions Calculation" href="/features/emissions-calculation" className="min-w-[20rem] py-4">
                              <p className="max-w-[20rem] whitespace-normal pt-1 text-sm font-normal leading-normal text-gray-700">
                                Provide shipment data and receive carbon footprint reports or CO<sub>2</sub>e values
                              </p>
                            </NavigationLink>
                            <NavigationLink navLinkText="Distance & Emissions API" href="/features/distance-emissions-api" className="py-4">
                              <p className="max-w-[20rem] whitespace-normal pt-1 text-sm font-normal leading-normal text-gray-700">
                                Distance and emissions values directly into your own software
                              </p>
                            </NavigationLink>
                          </div>
                          <div>
                            <div className="m-2 my-4 text-xs font-semibold uppercase">Network Promotion</div>
                            <NavigationLink
                              navLinkText="Embedded Direct Connections"
                              href="/features/direct-connections"
                              className="min-w-[20rem] py-4">
                              <p className="max-w-[20rem] whitespace-normal pt-1 text-sm font-normal leading-normal text-gray-700">
                                Promote your global intermodal network on your own website
                              </p>
                            </NavigationLink>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>
                <NavigationMenu.Item>
                  <NavigationMenu.Trigger
                    className={classNames('group flex select-none items-center justify-between gap-[2px] px-3', isDarkHeader ? 'py-1' : 'py-2')}>
                    <span
                      className={classNames(
                        'mb-[2px] pb-0 text-[15px] font-bold leading-[1.2] hover:border-b-2',
                        isDarkHeader
                          ? 'text-white hover:border-b-white hover:text-white'
                          : 'text-brand-secondary hover:border-b-brand-primary hover:text-brand-secondary-350'
                      )}>
                      Industries
                    </span>
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute left-0 top-0 w-full sm:w-auto">
                    <ul className="one m-0 flex list-none gap-x-[10px] p-[22px] lg:justify-center">
                      <li>
                        <h6 className="mb-1 flex items-center border-b border-gray-200 pb-2 font-bold">
                          <div className="flex h-8 w-8 items-center justify-center">
                            <ByRoleIcon className="h-6 w-6" />
                          </div>
                          <span className="text-xs uppercase">Industries</span>
                        </h6>
                        <NavigationLink
                          navLinkText="Cargo Owners & Freight Forwarders"
                          href="/roles/cargo-owners-freight-forwarders"
                          className="min-w-[20rem] py-4">
                          <p className="max-w-[20rem] whitespace-normal pt-1 text-sm font-normal leading-normal text-gray-700">
                            Improve your trade lane portfolio by finding better alternatives
                          </p>
                        </NavigationLink>
                        <NavigationLink navLinkText="Carriers & Operators" href="/roles/carriers-and-operators" className="py-4">
                          <p className="max-w-[20rem] whitespace-normal pt-1 text-sm font-normal leading-normal text-gray-700">
                            Grow your container business by receiving quote requests for container shipments
                          </p>
                        </NavigationLink>
                        <NavigationLink navLinkText="Ports & Terminals" href="/features/direct-connections" className="py-4">
                          <p className="max-w-[20rem] whitespace-normal pt-1 text-sm font-normal leading-normal text-gray-700">
                            Promote your trade lane network by showcasing your connections
                          </p>
                        </NavigationLink>
                      </li>
                    </ul>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>
                <NavigationMenu.Item>
                  <NavigationMenu.Trigger
                    className={classNames(
                      'group flex select-none items-center justify-between gap-[2px] px-3 leading-none no-underline',
                      isDarkHeader ? 'py-1' : 'py-2'
                    )}>
                    <span
                      className={classNames(
                        'mb-[2px] pb-0 text-[15px] font-bold leading-[1.2] hover:border-b-2',
                        isDarkHeader
                          ? 'text-white hover:border-b-white hover:text-white'
                          : 'text-brand-secondary hover:border-b-brand-primary hover:text-brand-secondary-350'
                      )}>
                      Resources
                    </span>
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className="absolute left-0 top-0 w-full sm:w-auto">
                    <ul className="one m-0 flex list-none gap-x-[10px] p-[22px] lg:justify-center">
                      <li>
                        <h6 className="mb-1 flex items-center border-b border-gray-200 pb-2 font-bold">
                          <div className="flex h-8 w-8 items-center justify-center">
                            <DataIcon className="h-5 w-5" />
                          </div>
                          <span className="text-xs uppercase">DATA</span>
                        </h6>
                        <NavigationLink navLinkText="Connected operators" href="/partners" className="min-w-[13.5rem] py-4"></NavigationLink>
                        <NavigationLink navLinkText="Schedule coverage" href="/schedule-coverage" className="py-4"></NavigationLink>
                      </li>
                      <li>
                        <h6 className="mb-1 flex items-center border-b border-gray-200 pb-2 font-bold">
                          <div className="flex h-8 w-8 items-center justify-center">
                            <DocumentationIcon className="h-5 w-5" />
                          </div>
                          <span className="text-xs uppercase">Documentation</span>
                        </h6>
                        <NavigationLink
                          navLinkText="API documentation"
                          href="https://docs.routescanner.com"
                          target="_blank"
                          className="min-w-[13.5rem] py-4"></NavigationLink>
                        <NavigationLink navLinkText="GLEC Emissions calculation method" href="/glec" className="py-4"></NavigationLink>
                        <NavigationLink navLinkText="SIG Data neutrality" href="/SIG" className="py-4"></NavigationLink>
                        <NavigationLink navLinkText="ISO Compliance" href="/ISO-14083" className="py-4"></NavigationLink>
                      </li>
                    </ul>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <NavigationMenu.Trigger
                    className={classNames('group flex select-none items-center justify-between gap-[2px] px-3', isDarkHeader ? 'py-1' : 'py-2')}>
                    <span
                      className={classNames(
                        'mb-[2px] pb-0 text-[15px] font-bold leading-[1.2] hover:border-b-2',
                        isDarkHeader
                          ? 'text-white hover:border-b-white hover:text-white'
                          : 'text-brand-secondary hover:border-b-brand-primary hover:text-brand-secondary-350'
                      )}>
                      About us
                    </span>
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className="absolute left-0 top-0 w-full shadow-sm sm:w-auto">
                    <ul className="one m-0 flex list-none gap-x-[10px] p-[22px] lg:justify-center">
                      <li>
                        <h6 className="mb-1 flex items-center border-b border-gray-200 pb-2 font-bold">
                          <div className="flex h-8 w-8 items-center justify-center">
                            <CompanyIcon className="h-5 w-5" />
                          </div>
                          <span className="text-xs uppercase">The company</span>
                        </h6>
                        <NavigationLink navLinkText="Our mission" href="/about" className="min-w-[9.5rem] py-4"></NavigationLink>
                        <NavigationLink navLinkText="Careers" href="/careers" className="py-4"></NavigationLink>
                        <NavigationLink navLinkText="Contact us" href="/contact" className="py-4"></NavigationLink>
                      </li>
                      <li>
                        <h6 className="mb-1 flex items-center border-b border-gray-200 pb-2 font-bold">
                          <div className="flex h-8 w-8 items-center justify-center">
                            <NotificationsIcon className="h-4 w-4" />
                          </div>
                          <span className="text-xs uppercase">Updates</span>
                        </h6>
                        <NavigationLink navLinkText="News" href="/news" className="min-w-[9.5rem] py-4"></NavigationLink>
                        <NavigationLink navLinkText="In the media" href="/news-room" className="py-4"></NavigationLink>
                        <NavigationLink navLinkText="Events" href="/events" className="py-4"></NavigationLink>
                      </li>
                    </ul>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Indicator className="data-[state=visible]:animate-fadeIn data-[state=hidden]:animate-fadeOut top-full z-[1] flex h-[10px] items-end justify-center overflow-hidden transition-[width,transform_250ms_ease]">
                  <div className="relative top-[70%] h-[10px] w-[10px] rotate-[45deg] rounded-tl-[2px] border border-gray-200 bg-white" />
                </NavigationMenu.Indicator>
              </NavigationMenu.List>

              <div className="perspective-[2000px] absolute left-0 top-full flex w-full justify-center">
                <NavigationMenu.Viewport className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-[6px] bg-white transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)]" />
              </div>
            </NavigationMenu.Root>
            <NavigationMenu.Root className="absolute right-0 top-1/2  z-10 hidden -translate-y-1/2 px-1 lg:flex lg:items-center lg:justify-end">
              <NavigationMenu.List className="flex items-center gap-4">
                {profile ? (
                  <>
                    {showToolButton ? (
                      <NavigationMenu.Item>
                        <NavigationMenu.Link asChild>
                          <Link
                            to="/app/voyages-search"
                            className={classNames(
                              'btn btn-secondary btn-sm h-10 min-h-10 bg-brand-secondary text-white hover:border-brand-primary hover:bg-brand-primary'
                            )}>
                            Go to Tool
                          </Link>
                        </NavigationMenu.Link>
                      </NavigationMenu.Item>
                    ) : null}
                    <div className="flex">
                      <NavigationMenu.Item>
                        <NavigationMenu.Trigger
                          className={classNames(
                            'items-left group flex flex-col-reverse justify-between gap-1 rounded pl-2 font-bold',
                            isDarkHeader
                              ? ' text-white  hover:bg-brand-tertiary-600 hover:text-gray-100'
                              : 'text-brand-secondary text-inherit hover:bg-gray-100 hover:text-gray-900'
                          )}>
                          {profile.organizationName ? <div className="badge badge-md px-2">{profile.organizationName}</div> : null}
                          <div className="flex items-center justify-between gap-1">
                            <UserIcon className="h-4 w-4" />
                            <div className=" flex min-w-[150px] items-center gap-1 text-left">
                              <span className="px-1 text-[15px]">{profile.displayName}</span>
                              <ChevronDownIcon className="mt-1 h-3 w-3" />
                            </div>
                          </div>
                        </NavigationMenu.Trigger>
                        <NavigationMenu.Content className="absolute grid w-auto gap-3 bg-white p-3 shadow-sm">
                          <NavigationLink
                            navLinkText="Account details"
                            href="/my-routescanner/account-details"
                            menuTextClass="text-inherit"
                            className="flex min-w-[10rem] items-center"
                            childBelow={
                              !profile?.email_verified ? (
                                <div className="relative ml-2.5 mt-[-10px]">
                                  <span className="absolute left-0 top-1 mt-[-10px] h-3 w-3 rounded-full bg-red-500"></span>
                                  <div className="absolute left-5 top-0 mt-[-10px] text-sm text-red-500">Verify email</div>
                                </div>
                              ) : undefined
                            }></NavigationLink>
                          <NavigationLink
                            href="/my-routescanner/search-history"
                            menuTextClass="text-inherit"
                            navLinkText="Search history"></NavigationLink>
                          <NavigationLink
                            href="/my-routescanner/quote-history"
                            menuTextClass="text-inherit"
                            navLinkText="Quote history"></NavigationLink>
                          {profile.roles?.includes(SCHEDULE_BUILDER_ADMIN) || profile.roles?.includes(SCHEDULE_BUILDER_EXTERNAL) ? (
                            <NavigationLink
                              navLinkText="Schedule builder"
                              menuTextClass="text-inherit"
                              href={scheduleBuilderUrl}
                              target="_blank"></NavigationLink>
                          ) : null}
                          {profile.roles?.includes(ROUTESCANNER_ADMINISTRATOR) ? (
                            <NavigationLink
                              href="/my-routescanner/co-sharing/overview"
                              menuTextClass="text-inherit"
                              navLinkText="Co-sharing overview"></NavigationLink>
                          ) : null}
                          <NavigationLink href="/logout" menuTextClass="text-inherit" navLinkText="Logout"></NavigationLink>
                        </NavigationMenu.Content>
                      </NavigationMenu.Item>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-row items-center gap-2">
                    <NavigationMenu.Item>
                      <NavigationMenu.Link asChild>
                        <Link
                          to={loginUrl}
                          className={classNames(
                            'btn btn-outline btn-sm h-10 min-h-10 font-bold leading-10  transition-colors',
                            isDarkHeader ? 'text-white hover:text-gray-100' : 'text-brand-tertiary'
                          )}>
                          Login
                        </Link>
                      </NavigationMenu.Link>
                    </NavigationMenu.Item>
                    <NavigationMenu.Item>
                      <NavigationMenu.Link asChild>
                        <Form method="post" action="/login?screen_hint=signup">
                          <button
                            className={classNames(
                              'btn btn-secondary btn-sm h-10 min-h-10',
                              isDarkHeader
                                ? 'bg-white text-black hover:bg-brand-primary-100'
                                : 'bg-brand-secondary text-white hover:bg-brand-secondary-600'
                            )}>
                            Register for free
                          </button>
                        </Form>
                      </NavigationMenu.Link>
                    </NavigationMenu.Item>
                  </div>
                )}
              </NavigationMenu.List>
            </NavigationMenu.Root>
            <div className="-mr-2 flex lg:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className={classNames(
                  'btn btn-square btn-ghost inline-flex items-center justify-center',
                  isDarkHeader && 'text-white hover:text-gray-100'
                )}
                aria-controls="mobile-menu"
                aria-expanded="false">
                <span className="sr-only">Open main menu</span>
                {!isOpen ? <Bars3Icon className="h-6 w-6 text-inherit" /> : <XMarkIcon className="h-6 w-6 text-inherit" />}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          {ref => (
            <div className="z-20 lg:hidden" id="mobile-menu">
              <div ref={ref} className="space-y-1 bg-base-100 px-2 pb-3 pt-0 sm:px-3">
                <ul className="flex flex-col  gap-4 overflow-y-auto border-t border-t-gray-300 p-4">
                  {/* <!-- Sidebar content here --> */}
                  <li className="font-bold">
                    <Link to="/">Home</Link>
                  </li>
                  <li key="solutions">
                    <details className="flex flex-col gap-2">
                      <summary className="font-bold">Solutions</summary>
                      <details className="ml-2 mt-2 flex flex-col gap-2">
                        <summary className="font-bold capitalize">Supply Chain Design</summary>
                        <Link to="/features/door-to-door" className="ml-4 py-2">
                          Door to Door
                        </Link>
                        <Link to="/features/route-optimizer" className="ml-4 py-2">
                          Route Optimizer
                        </Link>
                        <Link to="/app/connectivity-comparison" className="ml-4 py-2">
                          Connectivity Comparison
                        </Link>
                      </details>
                      <details className="ml-2 mt-2 flex flex-col gap-2">
                        <summary className="font-bold capitalize">Carbon Footprint Reporting</summary>
                        <Link to="/features/emissions-calculation" className="ml-4 py-2">
                          Emissions Calculation
                        </Link>
                        <Link to="/features/distance-emissions-api" className="ml-4 py-2">
                          Distance & Emissions API
                        </Link>
                      </details>
                      <details className="ml-2 mt-2 flex flex-col gap-2">
                        <summary className="font-bold capitalize">Network Promotion</summary>
                        <Link to="/features/direct-connections" className="ml-4 py-2">
                          Embedded Direct Connections
                        </Link>
                      </details>
                    </details>
                  </li>

                  <li key="industries">
                    <details className="flex flex-col gap-2">
                      <summary className="font-bold">Industries</summary>
                      <Link to="/roles/cargo-owners-freight-forwarders" className="ml-4 py-2">
                        Cargo Owners & Freight Forwarders
                      </Link>
                      <Link to="/roles/carriers-and-operators" className="ml-4 py-2">
                        Carriers & Operators
                      </Link>
                      <Link to="/features/direct-connections" className="ml-4 py-2">
                        Ports & Terminals
                      </Link>
                    </details>
                  </li>

                  <li key="resources">
                    <details className="flex flex-col gap-2">
                      <summary className="font-bold">Resources</summary>
                      <details className="ml-2 mt-2 flex flex-col gap-2">
                        <summary className="font-bold capitalize">Data</summary>
                        <Link to="/partners" className="ml-4 py-2">
                          Connected operators
                        </Link>
                        <Link to="/schedule-coverage" className="ml-4 py-2">
                          Schedule coverage
                        </Link>
                      </details>
                      <details className="ml-2 mt-2 flex flex-col gap-2">
                        <summary className="font-bold capitalize">Documentation</summary>
                        <Link to="https://docs.routescanner.com" target="_blank" className="ml-4 py-2">
                          API documentation
                        </Link>
                        <Link to="/glec" className="ml-4 py-2">
                          Emissions Calculation Method
                        </Link>
                      </details>
                    </details>
                  </li>

                  <li key="about">
                    <details className="flex flex-col gap-2">
                      <summary className="font-bold">About us</summary>
                      <details className="ml-2 mt-2 flex flex-col gap-2">
                        <summary className="font-bold capitalize">The company</summary>
                        <Link to="/about" className="ml-4 py-2">
                          Our mission
                        </Link>
                        <Link to="/careers" className="ml-4 py-2">
                          Careers
                        </Link>
                        <Link to="/contact" className="ml-4 py-2">
                          Contact us
                        </Link>
                      </details>
                      <details className="ml-2 mt-2 flex flex-col gap-2">
                        <summary className="font-bold capitalize">Updates</summary>
                        <Link to="/news" className="ml-4 py-2">
                          News
                        </Link>
                        <Link to="/news-room" className="ml-4 py-2">
                          In the media
                        </Link>
                        <Link reloadDocument to="/events" className="ml-4 py-2">
                          Events
                        </Link>
                      </details>
                    </details>
                  </li>

                  <>
                    {profile && (
                      <li key={profile.displayName}>
                        <details>
                          <summary className="font-bold">{profile.displayName}</summary>
                          <div className="flex flex-col gap-2">
                            <Link prefetch="intent" to="/my-routescanner/account-details" className="ml-4 py-2">
                              <div>Account details</div>
                              {!profile?.email_verified && (
                                <div className="flex items-center gap-2">
                                  <span className="h-3 w-3 rounded-full bg-red-500"></span>
                                  <div className="text-sm text-red-500">Verify email</div>
                                </div>
                              )}
                            </Link>
                            <Link prefetch="intent" to="/my-routescanner/search-history" className="ml-4 py-2">
                              Search history
                            </Link>
                            <Link prefetch="intent" to="/my-routescanner/quote-history" className="ml-4 py-2">
                              Quote history
                            </Link>
                            {(profile.roles?.includes(SCHEDULE_BUILDER_ADMIN) || profile.roles?.includes(SCHEDULE_BUILDER_EXTERNAL)) && (
                              <a href={scheduleBuilderUrl} target="_blank" rel="noreferrer" className="ml-4 py-2" key="schedule-builder">
                                Schedule builder
                              </a>
                            )}
                            {profile.roles?.includes(ROUTESCANNER_ADMINISTRATOR) && (
                              <Link prefetch="intent" className="ml-4 py-2" key="co-sharing" to="/my-routescanner/co-sharing/overview">
                                Co-sharing overview
                              </Link>
                            )}
                            <Link to="/logout" className="ml-4 py-2" key="logout">
                              Log Out
                            </Link>
                          </div>
                        </details>
                      </li>
                    )}
                  </>
                  {!profile && (
                    <div className="mt-3 flex gap-2 pl-5">
                      {/*  eslint-disable-next-line prettier/prettier  */}
                      <Link to={loginUrl} className="btn btn-secondary btn-outline">
                        Login
                      </Link>
                      <Form method="post" className="flex flex-1" action="/login?screen_hint=signup">
                        <button className="btn btn-secondary ml-2">Register</button>
                      </Form>
                    </div>
                  )}
                  <li className="hidden" aria-hidden="false">
                    Menu
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </>
  )
}

const NavigationLink = ({
  href,
  target,
  className,
  navLinkText,
  menuTextClass,
  childBelow,
  ...props
}: {
  target?: string
  href: string
  className?: string
  children?: ReactNode
  navLinkText: string
  menuTextClass?: string
  childBelow?: ReactNode
}): JSX.Element => {
  const matches = useMatches()
  const { pathname } = matches[matches.length - 1]
  const isActiveURL = pathname === href
  const linkClasses = classNames(
    'NavigationMenuLink block select-none px-3 py-2 leading-none no-underline group',
    props.children ? 'min-h-[105px]' : '',
    className
  )
  const spanClasses = classNames(
    'group-hover:border-b-brand-tertiary-700 mb-[2px] inline-flex whitespace-nowrap border-b-2 pb-0 text-base font-bold leading-[1.2] text-brand-tertiary-700',
    isActiveURL ? 'border-b-brand-tertiary-700' : 'border-b-transparent',
    menuTextClass
  )
  return (
    <NavigationMenu.Link asChild active={isActiveURL}>
      {target === '_blank' ? (
        <>
          <a href={href} target="_blank" title="" className={linkClasses} rel="noreferrer">
            <span className={spanClasses}>{navLinkText}</span>
            {props.children ? <div>{props.children}</div> : null}
          </a>
          <a href={href} target="_blank" title="" rel="noreferrer">
            {childBelow}
          </a>
        </>
      ) : (
        <>
          <Link prefetch="none" to={href} className={linkClasses}>
            <span className={spanClasses}>{navLinkText}</span>
            {props.children ? <div>{props.children}</div> : null}
          </Link>
          <Link prefetch="none" to={href}>
            {childBelow}
          </Link>
        </>
      )}
    </NavigationMenu.Link>
  )
}
